import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import PageBannerImage from '../../components/PageBannerImage'
import PageBannerText from '../../components/PageBannerText'
import OzonePurificationBanner from '../../images/products/MineralSwimV2_BENEFITS_OzonePurification_1920x1000.jpg'
import Holder from '../../images/image.png'
import { Link } from 'gatsby'
import { Check, } from 'react-feather'
const OzonePoolPurification = () => (
	<Layout style={{ paddingBottom: '1rem' }}>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Ozone Pool Purification: Clean, Safe, and Refreshing Pools | MineralSwim</title>
			<meta
				name="description"
				content="Explore the benefits of ozone pool systems and how they streamline pool care while keeping your pool clean and safe."
			/>
			<meta
				name="keywords"
				content="ozone pool purification, ozone purification, purification technology"
			/>
			<link rel="canonical" href="https://mineralswim.com/benefits/ozone-pool-purification/" />
		</Helmet>
		<main  className="bg-white relative">
			<header className='w-screen absolute main-page-header bg-center z-10'
						>
							<div className='mx-auto max-w-screen-xl h-full grid grid-cols-6 lg:grid-cols-12 lg:py-10 px-5 md:px-10'>						
							<div className='hidden lg:flex col-span-6'></div>
								<div className='text-center lg:text-left col-span-6 h-full flex flex-col justify-center md:gap-5 lg:gap-0'>
								<div className='bg-white bg-opacity-40 p-5 rounded-2xl '>
									<h1 className='text-black text-2xl md:text-4xl font-semibold'>Ozone Purification</h1>
									<div className='flex w-full justify-center items-center lg:justify-start'>
									<div className='w-20 md:w-16 h-1 mb-4 mt-4 md:mt-2 border-2 border-black'/>	
									</div>
									<p className='text-black md:text-base leading-6'>Discover how ozone purification offers powerful, eco-friendly pool sanitation, reducing chlorine use and enhancing water quality for a healthier swim. 					
										</p>					
										<div className='w-full flex justify-center lg:justify-start mt-7 md:mt-10'>			              
										<Link to="/s/?page=benefits&article=ozone-pool-purification">             
									<p className='text-white text-base font-bold bg-gray-800 px-8 rounded-lg py-1 hover:bg-gray-200 hover:text-black cursor-pointer uppercase'>Learn more</p>	
									</Link>			
									</div>	
									</div>								
									</div>                
							</div>
								</header>
				<div className='top-0 bg-cover bg-right lg:bg-center z-0' style={{
                backgroundImage: `url(${OzonePurificationBanner})`,
								backgroundAttachment: 'fixed',
								position: 'relative',
								height: '90vh',
            }}
						data-scroll
      data-scroll-speed="-10"
						>
							
								</div>
								<div className='relative -mt-24 md:-mt-24 lg:-mt-40 pt-40 pb-20 bg-white z-10'>
				<section  id="main-content" className="max-w-screen-xl font-display text-base mx-auto mb-20 text-justify md:text-left px-8 md:px-10 grid grid-cols-1 md:grid-cols-2 gap-10">
				<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Introduction to Ozone Purification </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Ozone purification is a powerful method for maintaining clean and healthy pool water. The Mineral Swim™ system integrates this technology to provide an effective, eco-friendly alternative to traditional pool sanitisation methods. </p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/61945224830introductiontoozonepurification_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=kZ4ZI04z0aPdAB0MvLEfxJJ%2FKAOuttSEVZIrdeIqazS0hdwBvg1GJy3hG3sIrEmWfB2LqyUq2QHPlIshzSKu6Laq2EYvhjyoRgK8rQBMrmgCekbszK3iza1nT6aNSxmDbuqKuiqw6%2Fd8HSsz2v7pBlXXEbzW2F7kL1LF6wwUIW93GOKkDpMu9mupeBwQUiIp%2FeOoSiFKEypFPS8qDFcqxOWF1HKopgrkt8NEErR%2FcXF4NY7powJlrtr6K9kn75Lbs6GZIHBRJ1ZfSpv37%2B72PhsqaKrjJeyAOlGDFag1aUILeTGsjSzs2JZ3GUODDIpHHcGkaZ6Bvp54rRr8AsNQAw%3D%3D" alt='Introduction to Ozone Purification' className='w-full h-72 border object-cover rounded-2xl'/>		
				<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>The Challenge of Traditional Pool Maintenance </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>As a pool owner, you are aware of the constant need to keep your pool clean and prevent algae, pathogens, and parasites that can make it unsafe for swimming. It is important to check the chemical balance weekly, add chemicals if needed, keep the pool interior clean, and ensure the filtration system works properly. You frequently question if there is a way to simplify pool upkeep while retaining a clean pool. If you've been thinking about pool upkeep, it might be time to consider looking into ozone purification. </p>
					</div>			
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/37219025676thechallengeoftraditional_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=w2trya8v9jpJDnJpkBRxu5p4rWvlLF1g3PKr9EEO6BUsuW3%2FX73pAl55dzRh8Wc8ef6fmYjHdIgZCTgwMPtwV%2BjYpOvvdfYlzDKqZyIeJVtVjM9Y80CmcSEmjVSB0wpZbspfUjYfn6ZjQ7sXaY8e28oQh%2B2EIRHn3UVKo7cOuWNpDmu21%2BNaJyi5jdeNblq3h1%2BlM9F6ZQs7MztTZjU983y0TOJ6%2FqNjB%2F20MCucutXVHM2oaqGUKCp0MZwwZSHdGG7tGCnmNG4MaN8rbHGH9%2BOFbCrY%2BfFNflj68YUVEzW%2FLNXZNqzEUT0OCPdsCJCURTrjf75JhMBKiX1hRxYVpg%3D%3D" alt='The Challenge of Traditional Pool Maintenance' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>What is Pool Ozone? </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p> Ozone is a gas made up of three oxygen atoms that is both man-produced and naturally occurring in the Earth's upper and lower atmospheres. Because ozone is an oxidant, it can chemically eliminate pollutants in water, making it a highly effective agent for pool treatment. Unlike traditional sanitisers, ozone is administered to the water within the circulation system and returns to the pool in small concentrations. Because ozone can be hazardous in large amounts, it must be created and dissolved in water as soon as possible. Pool ozone is a gas that cannot be stored or transported and must be generated on-site with an ozone generator. </p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/82864657058whatispoolozone_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=IdbNbvzGCjph9%2FWDCVoyy19kWMbBReDrTQnUYRz9YBiY5e9j5L3WpCYphfSVDDDV%2B8Thl3TnAAu5Ouqa9SXAbZyusQVlkpIEkmMOGlBB%2FOqytHFPH1pkp5YJu03ZDBXEGAQSJoKKfrQ5nEOMrpVjGwquF4yo%2FvCScDio1NtHagzZWlIS18VsaFU9%2Bk7MTlegllrFUu0zrSF46v5XmEdBJJDSuGYPOYcZu1DNp7YVaaEw20uy1HnhYkK%2FJ2iIfn%2BrPGDll4WhXlLQb4oa9ABVdKBHUy397w6WsjIv1N2R8v8IE3NbxrtONie5qXrqtiX5KBxAccmR2%2F0vIWrIXZjr5Q%3D%3D" alt='What is Pool Ozone' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>How Ozone Purification Works </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Ozone generators, also known as ozonators, sterilise pool water as it circulates through the pool's system. Ozonators generate ozone using corona discharge. To understand how this works, remember that oxygen includes two oxygen atoms (O2), whereas ozone contains three (O3). 
					<ul className='flex flex-col pl-2 sm:pl-4 py-8 gap-4'>
					<li>
						<h4 className='text-xl font-semibold mb-2'>Generation</h4>
						<p>Oxygen is converted to pure ozone. Corona discharge ozonators create an environment for ozone formation by splitting the oxygen (O2) molecules in the air into individual atoms using a ring of energy produced by high-voltage electrical discharge. The isolated oxygen molecules re-join the O2 molecules in the air, creating ozone (O3). </p>
						</li>
					<li>
						<h4 className='text-xl font-semibold mb-2'>Purification </h4>
						<p>Ozone immediately oxidises and neutralises harmful contaminants. After ozone is produced by a corona discharge ozonator, it must be dissolved in water to sterilise and destroy pollutants such as bacteria, viruses, and parasites. Ozone dissolution is accomplished through a technique known as venturi injection, in which pressurised water creates a vacuum, drawing new ozone into the pool water as it disperses throughout the system.</p>
						</li>
					<li>
						<h4 className='text-xl font-semibold mb-2'>Reverts to Nature </h4>
						<p>Ozone breaks into oxygen after sanitising pollutants, resulting in softer, cleaner pool water with no residual odour. </p>
						</li>
					</ul>
                    </p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/62985997144howozonepurification_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=JobfqPV11qz565fVE1xC97j9w2FU%2BeJwGmYnOIpaDR3Cy5Qhvd1pODJvY6pHTp1lUUxuQQNIvAzWG2bgnRjixx0XdHSWUf7i%2Fak3YlWCReeN8JJfOHqHu%2Bs1bxpThbioGpp3QlpAeny6yzXvy%2B2qXEJyiTqnPRISIZfLWAHMYuIVcQeRVgQXMh29%2FdR3w70ZNWC0563JjTFKXnUm8hS4Lwd3oMQKm8WTdBG7UspOke8y5cAUBhRMMQ2tgAxbluWfSiuevlYZPeSvfwoH5XrLsCUv%2F%2Fb92LkFJ00KiF7%2BQCrUuN3pLai762CMbzQg4mRAbPhFrIpUla%2Biv45b2fGdtg%3D%3D" alt='How Ozone Purification Works' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>The Benefits of Ozone Purification  </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>
					
					<p>The ozone generated from the Mineral Swim&trade; V2 Purifier Module will do most of the sanitiser work in your pool 	
					<ul className='flex flex-col pl-2 sm:pl-4 py-8 gap-2'>
					<li className='flex'>
				<div className='w-8'><Check tw="mx-auto md:mx-auto" /></div>
				<p>Noticeably softer, hygienically clean water</p>
						</li>
					<li className='flex'>
				<div className='w-8'><Check tw="mx-auto md:mx-auto" /></div>
				<p>Safe for people who suffer from allergies, asthma & eczema </p>
						</li>
					<li className='flex'>
				<div className='w-8'><Check tw="mx-auto md:mx-auto" /></div>
				<p>Eliminates red eyes & itchy skin </p>
						</li>
					<li className='flex'>
				<div className='w-8'><Check tw="mx-auto md:mx-auto" /></div>
				<p>Environmentally friendly </p>
						</li>
					<li className='flex'>
				<div className='w-8'><Check tw="mx-auto md:mx-auto" /></div>
				<p>Reduces your pool chlorine levels by up to 80% </p>
						</li>
					<li className='flex'>
				<div className='w-8'><Check tw="mx-auto md:mx-auto" /></div>
				<p>Fewer additional chemicals are required </p>
						</li>
					<li className='flex'>
				<div className='w-8'><Check tw="mx-auto md:mx-auto" /></div>
				<p>Prolongs equipment & salt cell life </p>
						</li>
					<li className='flex'>
				<div className='w-8'><Check tw="mx-auto md:mx-auto" /></div>
				<p>Save time and money on pool maintenance </p>
						</li>
					<li className='flex'>
				<div className='w-8'><Check tw="mx-auto md:mx-auto" /></div>
				<p>Improves water clarity </p>
						</li>
					</ul>

					The Mineral Swim™ system’s ozone purification technology offers a superior, eco-friendly alternative to traditional pool sanitation methods. Reducing chlorine usage and improving water quality ensures a healthier and more enjoyable swimming experience. Embrace the benefits of ozone purification for a cleaner, clearer, and more refreshing pool.</p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/20826056082thebenefitsofozonepurification_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=FrzIYMlCdTVMaFdtAGLHd4%2Fl0DQP7J%2Fnx8dNO6A7sQ8wHa59mv7Xyqm%2FZB4cUbxZnI5MESFIq04h3uyBeXISp4S%2BymGJqOEI%2BkHfbmc6emNX4SQzVnt%2FDfxMAvMQlPlNZn4hHOvHEgp09AZEbUlEJJMaxecWxvoUzyyTlXHZxUvBKetOzzkLwcZyT9ZUIfKMsdd9B0P0u80gOvjt0nDMxDkNdzzHqjWFfXTNsou%2FeiC0oMPVhFFHg9iC0L%2BQ1XR%2FeWJ6FqT9yBSDMRcK9lYaqqwXTEdAZ8IULRCfP7Na7stk6xER9yaIBur04y3vMDKrhE8XhL3zjmVEygZ88D5i1Q%3D%3D" alt='The Benefits of Ozone Purification' className='w-full h-72 border object-cover bg-center rounded-2xl'/>	
				</section>
				<div className="flex flex-col sm:flex-row gap-4 items-center justify-center lg:flex-1 font-display text-base font-semibold uppercase">
					<Link
					to="/benefits"
									className="cursor-pointer flex items-center justify-center  w-48 py-1 border border-transparent rounded-md shadow-sm text-base font-medium text-gray-800 bg-gray-100 hover:bg-gray-300 whitespace-nowrap"
								>
									Back to Benefits
								</Link>
								<Link
							to="https://www.maytronics.com.au/shop?brand=Mineral%2520Swim"
							className="whitespace-nowrap inline-flex items-center justify-center w-48 py-1 border border-transparent rounded shadow-sm bg-blue-500 hover:bg-blue-400 text-white"
						>
							Buy Now
						</Link>
					</div>
				</div>
				</main>
	</Layout>
)

export default OzonePoolPurification
